import React from 'react';
import ApplyFormComponent from '../component/Apply/applyFormComponent';
import FooterComponent from '../component/footerComponent';
import Layout from '../component/layout';
import { graphql } from 'gatsby';
const Apply = props => {
  const {
    data: {
      wpPage: { seo },
    },
    pageContext: { title },
  } = props;
  return (
    <Layout title={title} seo={seo}>
      <ApplyFormComponent
        applyTitle='Apply now'
        applyDescription='We hire for character and invest in you to build the skills. We are looking for individual who who we can trust, 
        take initiative, are dedicated, eager to learn, are resilient, helpful to others, and flexible. If you possess these attributes,
        feel free to send your application to one of the positions you are qualified for.'
        contact='Contact Us'
        number='(+355) 67-544-6541'
        email='imelda@uptechshpk.com'
        name='UpTech Sh.P.K'
        location='Komuna Parisit , Kompleksi Kika 2 Tirana, Albania'
        firstName='First Name'
        lastNmae='Last Name'
        message='Message'
        formButton='Apply to job'
        emailInput='Email'
        cv='CV'
      />
      <FooterComponent />
    </Layout>
  );
};
export const query = graphql`
  query {
    wpPage(slug: { eq: "apply-now" }) {
      seo {
        metaDesc
        title
      }
    }
  }
`;
export default Apply;
