import React, { useCallback, useMemo, useState } from 'react';
import HeaderComponent from '../headerComponent';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { ToastContainer, toast } from 'react-toastify';
const ApplyFormComponent = props => {
  const {
    applyTitle,
    applyDescription,
    contact,
    number,
    email,
    name,
    location,
    firstName,
    message,
    formButton,
  } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {},
  });

  const [previewFile, setPreviewFile] = useState({});
  const [myFiles, setMyFiles] = useState([]);

  const onDrop = useCallback(
    acceptedFiles => {
      setMyFiles([...acceptedFiles]);
    },
    [myFiles]
  );

  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [hasFileError, setHasFileError] = useState(false);

  const onDropAccepted = ([file]) => {
    let reader = new FileReader();
    reader.onloadend = () => {
      setHasFileError(false);
      setPreviewFile({
        fileName: file?.path,
        path: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
    },
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
    onDropAccepted,
    maxSize: 6145728,
    onDrop,
  });

  const files = useMemo(() => {
    return myFiles?.map(file => (
      <li key={file.path} className='apply-fom-section-upload-input-text'>
        {file.path}
      </li>
    ));
  }, [myFiles]);

  const onSubmitHandler = values => {
    setLoading(true);
    const { firstName, lastName, email, message, fileName } = values;
    console.log(fileName);
    if (!previewFile?.path) {
      setHasFileError(true);
      setLoading(false);
      return;
    }

    const postUrl = `https://api-mail.motomtech.com/send-email`;
    axios
      .post(postUrl, {
        firstName,
        lastName,
        email,
        message,
        attachments: [previewFile],
        subject: 'Job Application',
      })
      .then(response => {
        console.log(response);
        setLoading(false);
        setIsSuccess(true);
        setPreviewFile({});
        setMyFiles([]);
        toast.success('Message sent successfully', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        reset();
      })
      .catch(err => {
        setIsSuccess(false);
        setIsError(true);
        setLoading(false);
        console.log(errors);
        toast.error('There was a problem. Please check again later.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      });
  };
  return (
    <div className='apply-fom-section'>
      <HeaderComponent />
      <div className='full-container'>
        <div className='apply-fom-section-wrapper'>
          <h1 className='apply-fom-section-title'>{applyTitle}</h1>
          <p className='apply-fom-section-description'>{applyDescription}</p>
          <div className='row'>
            <div className='col-md-4 order-2 order-md-1'>
              <div className='apply-fom-section-text'>
                <p className='apply-fom-section-text-subtitle'>{contact}</p>
                <p className='apply-fom-section-text-description'>{number}</p>
                <p className='apply-fom-section-text-description'>{email}</p>
              </div>
              <div className='apply-fom-section-text'>
                <p className='apply-fom-section-text-subtitle'>{name}</p>
                <p className='apply-fom-section-text-description'>{location}</p>
              </div>
            </div>
            <div className='col-md-8 order-1 order-md-2'>
              <div className='contact-home-section-form apply-fom-section-submit'>
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <div className='row'>
                    <div className='col-md-6'>
                      <label
                        className='contact-home-section-form-label'
                        htmlFor='firstname'
                      >
                        {firstName}
                      </label>
                      <div className='contact-home-section-form-input-section'>
                        <input
                          type='text'
                          className={`contact-home-section-form-input ${
                            errors.firstName ? 'is-invalid' : ''
                          }`}
                          placeholder='First name'
                          id='firstName'
                          name='firstName'
                          {...register('firstName', {
                            required: 'Enter your first name!',
                          })}
                        />
                        <div className='invalid-feedback'>
                          {errors.firstName?.message}
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <label
                        className='contact-home-section-form-label'
                        htmlFor='lastName'
                      >
                        Last Name
                      </label>
                      <div className='contact-home-section-form-input-section'>
                        <input
                          type='text'
                          className={`contact-home-section-form-input ${
                            errors.lastName ? 'is-invalid' : ''
                          }`}
                          placeholder='Last name'
                          id='lastName'
                          name='lastName'
                          {...register('lastName', {
                            required: 'Enter your last name!',
                          })}
                        />
                        <div className='invalid-feedback'>
                          {errors.lastName?.message}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <label
                      className='contact-home-section-form-label'
                      htmlFor='email'
                    >
                      Email
                    </label>
                    <div className='contact-home-section-form-input-section'>
                      <input
                        type='email'
                        className={`contact-home-section-form-input ${
                          errors.email ? 'is-invalid' : ''
                        }`}
                        placeholder='you@company.com'
                        id='email'
                        name='email'
                        {...register('email', {
                          required: 'Enter your email!',
                        })}
                      />
                      <div className='invalid-feedback'>
                        {errors.email?.message}
                      </div>
                    </div>
                  </div>
                  <label
                    className='contact-home-section-form-label'
                    htmlFor='cv'
                  >
                    CV
                  </label>
                  <div
                    className={`apply-fom-section-upload ${
                      hasFileError ? 'is-invalid' : ''
                    } `}
                  >
                    {console.log(myFiles)}
                    <section className={` container `}>
                      <div {...getRootProps({ className: 'dropzone' })}>
                        <div className='apply-fom-section-upload-input'>
                          <input {...getInputProps()} />
                          <ul>
                            {files?.length > 0 ? (
                              files
                            ) : (
                              <li className='apply-fom-section-upload-input-text'>
                                .PDF files only. Max file size 3MB
                              </li>
                            )}
                          </ul>
                        </div>
                        <button
                          type='button'
                          onClick={open}
                          className='apply-fom-section-upload-choose'
                        >
                          Choose
                        </button>
                      </div>
                    </section>
                  </div>
                  <div className='invalid-feedback'>
                    Please select your file
                  </div>
                  <label
                    className='contact-home-section-form-label'
                    htmlFor='message'
                  >
                    {message}
                  </label>
                  <div className='contact-home-section-form-input-section'>
                    <textarea
                      rows={4}
                      type='text'
                      className={`contact-home-section-form-input`}
                      {...register('message')}
                    />
                  </div>
                  <button
                    type='submit'
                    className='contact-home-section-form-button'
                  >
                    {!loading ? (
                      <>{formButton}</>
                    ) : (
                      <div className='spinner-border text-light' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    )}
                  </button>
                </form>
              </div>
              <ToastContainer
                position='top-right'
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ApplyFormComponent;
